import { notification, Tooltip } from 'antd'
import { computed } from 'mobx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    PaymentLink,
    PaymentLinkDetails,
    PaymentLinkGrid,
    PaymentLinksBaseInfo,
    PaymentLinkStatus
} from '~/api/payment-links'
import { Column } from '~/components/grid'
import {
    CancelCircleIcon,
    ContractIcon,
    PaymentLinksIcon
} from '~/components/icon'
import strict from '~/utils/strict'
import PaymentLinksStore from '../payment-links-store'
import styles from '../styles.css'
import res from './res'

export const renderDate = (value, hideHours?: boolean, inline?: boolean) => {
    const dateRender = value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span> {inline && '/ '}
            {!hideHours && (
                <span
                    className={`${styles.dateHours} ${inline ? styles.inline : ''
                        }`}
                >
                    {moment(value).format('hh:mm')}
                </span>
            )}
        </span>
    ) : null

    return dateRender
}

export const fields = (store: PaymentLinksStore) =>
    computed(() => {
        const { titles } = res().dataFields

        const renderActions = (value, link: PaymentLink) => {
            return (
                <div className={styles.actions}>
                    <Tooltip title={res().tooltips.copyLink}>
                        <span>
                            {link.status !==
                                PaymentLinkStatus.active
                                    .toString()
                                    .toUpperCase() ? (
                                <PaymentLinksIcon
                                    onClick={() =>
                                        notification.warn({
                                            message: res()
                                                .inactiveLinkWarningTitle,
                                            description: res()
                                                .inactiveLinkWarning
                                        })
                                    }
                                    data-semantic-id="copy-link-icon"
                                    className={`${styles.icon} ${styles.copyIcon}`}
                                />
                            ) : (
                                <PaymentLinksIcon
                                    onClick={() => store.copyLink(link)}
                                    data-semantic-id="copy-link-icon"
                                    className={`${styles.icon} ${styles.copyIcon}`}
                                />
                            )}
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            link.status === PaymentLinkStatus.active
                                ? res().tooltips.cancelLink
                                : ''
                        }
                    >
                        <span>
                            <CancelCircleIcon
                                data-semantic-id="cancel-link-icon"
                                className={`${styles.icon} ${link.status === PaymentLinkStatus.active
                                    ? styles.activeCancelIcon
                                    : ''
                                    }`}
                                onClick={() => store.cancelLink(link.id)}
                            />
                        </span>
                    </Tooltip>
                </div>
            )
        }

        return strict<Array<Column<PaymentLinkGrid>>>([
            {
                field: 'created_date',
                title: titles.createdDate,
                render: value => renderDate(value, false, true),
                width: 15
            },
            {
                field: 'expire_date',
                title: titles.endDate,
                render: value => renderDate(value, false, true),
                width: 14
            },
            {
                field: 'invoice_id',
                title: titles.order,
                render: value => {
                    return (
                        <div className={styles.order}>
                            <span>
                                <ContractIcon />
                                &nbsp;
                            </span>
                            <span>{value}</span>
                        </div>
                    )
                },
                width: 15
            },
            {
                field: 'invoice_alt',
                title: titles.invoice_alt,
                render: value => {
                    return (
                        <div className={styles.order}>
                            <span>
                                <ContractIcon />
                                &nbsp;
                            </span>
                            <span>{value}</span>
                        </div>
                    )
                },
                width: 15
            },
            {
                field: 'amount',
                title: (
                    <span className={`${styles.sumTitle} ${styles.rightAlign}`}>
                        {titles.sum}
                    </span>
                ),
                render: value => (
                    <span className={`${styles.block} ${styles.rightAlign}`}>
                        {value}
                    </span>
                )
            },
            {
                field: 'recipient_contact',
                title: titles.notificationEmail,
                render: (value: string) => (
                    <span className={styles.mainColor}>
                        {value}
                    </span>
                ),
                width: 12
            },
            {
                field: 'recipient_contact_sms',
                title: titles.notificationPhone,
                render: (value: string) => (
                    <span className={styles.mainColor}>
                        {value}
                    </span>
                ),
                width: 12
            },
            {
                field: 'language',
                title: titles.language,
                render: (value: string) => (
                    <span className={styles.mainColor}>
                        {res().languages[value.toLowerCase()]}
                    </span>
                ),
                width: 12
            },
            {
                field: 'status',
                title: titles.status,
                render: value => {
                    let className = styles.mainColor
                    if (value === PaymentLinkStatus.active.toUpperCase()) {
                        className = styles.activeStatus
                    } else if (
                        value === PaymentLinkStatus.annulled.toUpperCase()
                    ) {
                        className = styles.cancelledStatus
                    } else if (
                        value === PaymentLinkStatus.expired.toUpperCase()
                    ) {
                        className = styles.expiredStatus
                    }

                    return (
                        <span className={className}>
                            {res().postLinkStatuses[value]}
                        </span>
                    )
                },
                width: 11
            },
            // {
            //     field: 'merchantId',
            //     title: titles.merchantId
            // },
            // {
            //     field: 'description',
            //     title: titles.description
            // },
            // {
            //     field: 'emailToSend',
            //     title: titles.emailToSend
            // },
            // {
            //     field: 'phoneToSend',
            //     title: titles.phoneToSend
            // },
            // {
            //     field: 'notificationEmail',
            //     title: titles.notificationEmail
            // },
            // {
            //     field: 'notificationPhone',
            //     title: titles.notificationPhone
            // },
            {
                field: 'actions',
                title: '',
                render: renderActions
            }
        ])
    })
